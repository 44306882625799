//Chakra UI
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons'

import ArticleCard from '../components/article-card'
import Layout from "../layout"
import PagButton from "../components/pagination"
import PageTransition from 'gatsby-plugin-page-transitions'
import React from "react"
//Icons
import Svg from "../images/statys-background-animation-small.svg"
import { graphql } from "gatsby"

const BlogIndex = ({ data, pageContext: { nextPagePath, previousPagePath }, }) => {
  const isMobile = useBreakpointValue({ base: true, xl: false })
  const posts = data.allWpPost.nodes

  //Automaticly display how many pages are there based on pageCount
  const totalCountOfPosts = posts.length
  const readingSettings = data.wp.readingSettings.postsPerPage

  function calculateNumberOfPages (readingSettings, totalCountOfPosts) {
    return totalCountOfPosts < readingSettings ? 1 : Math.ceil(totalCountOfPosts / readingSettings);
  }

  const total = calculateNumberOfPages(readingSettings, totalCountOfPosts)

  //array od numbers from total
  const pag = []
  for (var i = 0; i <= total; i++){
    if (i !== 0) {
          pag.push(i + 1)
    }
  }

  return (
    <Layout>
      <PageTransition>
      <Container maxW="container.xl" pt="140px">
        {!isMobile && (<Image src={Svg} alt="Background animation" style={{position: `absolute`, right: `0`, top: `72px`}} ></Image> )}
        <Box pb="40">
          <Heading textAlign="center" m="0px !important" fontSize="60px!important" color="blue.600" fontWeight="600" fontFamily="Gotham Light">Insights</Heading>
          {/* <Text textAlign="center" color="gray.600" pt="6"> Insights from Statys Connect </Text> */}
        </Box>
            
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: '12', lg: '9' }}>
          {posts.map((post) =>(
              <ArticleCard articles={post}></ArticleCard>
          ))}
        </SimpleGrid>
       
        {/* PAGINATION */}
        <Flex pb={28} w="full" alignItems="center" justifyContent="center">
          <Flex>
            {previousPagePath && (<Link href={previousPagePath}> <PagButton> <Icon as={IconArrowLeft} color="base.50" boxSize={4} /> </PagButton> </Link>)}
            <Link href={"/insights/"}><PagButton >1</PagButton></Link>
              
            {pag.map((item) =>
      
                <Link href={"/insights/" + item}><PagButton >{item}</PagButton></Link>
            
            )}
            {nextPagePath &&  (<Link href={nextPagePath}> <PagButton> <Icon as={IconArrowRight} color="base.50" boxSize={4}/> </PagButton> </Link>)}
            </Flex>
        </Flex>
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogInsights($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "insights"}}}}}
    ) {
      nodes {
        id
        link
        slug
        excerpt
        uri
        content
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
         featuredImage {
          node {
            link
            srcSet
            sourceUrl
         }
        }
      }
    }
     wp {
        readingSettings {
          postsPerPage
        }
      }
  }
`
